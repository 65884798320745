
// Content object
const content = {
    "89f8f273-16d3-4434-ab73-73b03819b3d1": {
        tabName: "Adventure",
        originalContent: `
            <h5 class="card-title mb-4">Why Your Next Vacation Should Be an Adventure, Not a Resort</h5>
            <p>When was the last time you truly explored the world around you? Adventure vacations offer more than just sights\u2014they bring you experiences that engage your senses and challenge your comfort zones. Whether it\u2019s trekking through rugged landscapes, cycling through charming towns, or immersing yourself in local traditions, these trips connect you to a deeper, more authentic side of travel. They aren\u2019t just about where you go, but how you grow along the way.</p>
            <p>Ditch the poolside lounge chair and embrace the thrill of discovery. Adventures allow you to break from the monotony of routine and bring home stories you\u2019ll cherish forever. From tasting local cuisine made with your own hands to feeling the wind in your hair as you traverse scenic routes, an adventure vacation isn\u2019t just a getaway\u2014it\u2019s a gateway to lifelong memories and personal transformation.</p>
        `
    },
    "5ea28d01-1486-448b-b182-b64e5661eb7b": {
        tabName: "Advertising",
        originalContent: `
            <h5 class="card-title mb-4">Reimagining Advertising</h5>
            <p>Online advertising is evolving. Gone are the days of intrusive pop-ups and disruptive banners. Today, seamless integration is the future \u2014 a way to connect with audiences without interrupting their online journeys. In-content advertising, powered by cutting-edge AI, enables ads to blend naturally with the content people are already engaging with. It\u2019s not just smarter advertising; it\u2019s a more respectful, intuitive way to communicate.<\/p>
            <p>This shift benefits everyone. Readers enjoy uninterrupted, immersive experiences, while advertisers achieve meaningful engagement without the backlash of obtrusive tactics. By weaving messages directly into the digital narrative, brands and publishers alike can foster trust and authenticity. The future of advertising isn\u2019t about standing out \u2014 it\u2019s about fitting in where it matters most.<\/p>
        `
    },
    "e0d630c5-8167-4daf-8356-c14e406fcead": {
        tabName: "Sports",
        originalContent: `
            <h5 class="card-title mb-4">The Everyday Athlete: Why Sports Aren\u2019t Just for Pros</h5>
            <p>Athleticism is no longer reserved for the elite. The rise of the \"everyday athlete\" celebrates individuals who find joy and purpose in physical activity, no matter their skill level. Whether it\u2019s joining a bike club, experimenting with a new outdoor hobby, or simply staying active through mindful practices, there\u2019s a growing movement that redefines what it means to be sporty. It\u2019s not about competition\u2014it\u2019s about embracing health, vitality, and self-improvement.</p>
            <p>Incorporating movement into your day is easier than you think. From commuting on two wheels to rehydrating after a workout, small choices add up to a healthier, more dynamic lifestyle. When you engage in regular activity, you\u2019re not just building strength\u2014you\u2019re boosting mental clarity, confidence, and resilience. The everyday athlete isn\u2019t defined by medals or accolades but by the spirit of participation.</p>
        `
    }
    // ,
    // "e55c2ccc-48f3-428a-9c9f-edcc9034069f": {
    //     tabName: "Sustainability",
    //     originalContent: `
    //         <h5 class="card-title mb-4">The Art of Everyday Sustainability</h5>
    //         <p>Sustainability isn\u2019t just a buzzword\u2014it\u2019s a way of life that harmonizes convenience and care for the planet. Every small choice, from the way we travel to the tools we use daily, impacts our environment. Incorporating sustainable habits into your routine doesn\u2019t mean sacrificing style or functionality; instead, it\u2019s about choosing solutions that benefit both you and the world around you.</p>
    //         <p>Living sustainably can transform your everyday experience into something meaningful. Whether you\u2019re opting for eco-conscious travel gear, embracing clean transportation, or supporting local initiatives, these choices ripple outward to create lasting change. By adopting a greener lifestyle, you not only lighten your environmental footprint but inspire others to take their first steps toward a more sustainable future.</p>
    //     `
    // },
    // "ab5a3bbf-5649-41a6-a306-990b259c9dac": {
    //     tabName: "Minimalism",
    //     originalContent: `
    //         <h5 class="card-title mb-4">The Power of Minimalism in Modern Living</h5>
    //         <p>Life is often cluttered with unnecessary distractions, but minimalism offers a path to clarity and intention. By focusing on what truly matters, you can simplify your environment and free up mental space. Whether it\u2019s curating your possessions, refining your daily habits, or streamlining your routines, minimalism invites you to live with purpose and grace.</p>
    //         <p>This isn\u2019t about deprivation; it\u2019s about liberation. Imagine fewer things to maintain, less stress, and more room for what sparks joy\u2014whether it\u2019s exploring new places, mastering a new skill, or simply being present. A minimalist mindset helps you find beauty in simplicity, empowering you to prioritize meaningful experiences over material excess.</p>
    //     `
    // }
};

var currentTab = "89f8f273-16d3-4434-ab73-73b03819b3d1";
var firstTimeContentGenerated = false;
// Generate tabs dynamically
const tabList = document.getElementById('demoTabs');
const originalContentDiv = document.getElementById('original-content');
const modifiedContentDiv = document.getElementById('modified-content');
const modifiedContentCardDiv = document.getElementById('modified-content-card');
const generateContentButton = document.getElementById('generate-button');
const refreshContentButton = document.getElementById('refresh-button');
const topNav = document.getElementById('top-nav');

modifiedContentDiv.style.transition = 'height 0.3s ease-in-out';
modifiedContentDiv.style.overflow = 'hidden';

function renderCurrentTabContent() {
    originalContentDiv.innerHTML = content[currentTab].originalContent;
    modifiedContentDiv.innerHTML = content[currentTab].modifiedContent || "";
    generateContentButton.setAttribute('data-key', currentTab);
    refreshContentButton.setAttribute('data-key', currentTab);

    if (content[currentTab].modifiedContent) {
        modifiedContentCardDiv.classList.add('ready');
    } else {
        modifiedContentCardDiv.classList.remove('ready');
    }
}

// Create tabs from content object
Object.keys(content).forEach((key, index) => {
    const li = document.createElement('li');
    li.className = 'nav-item';

    const a = document.createElement('a');
    a.className = `nav-link ${index === 0 ? 'active' : ''}`;
    a.href = '#';
    a.setAttribute('data-key', key);
    a.textContent = content[key].tabName;

    a.addEventListener('click', (e) => {
        e.preventDefault();
        if (e.target.classList.contains('active')) {
            return; // Do nothing if the tab is already active
        }

        tabList.querySelectorAll('.nav-link').forEach(tab => {
            tab.classList.remove('active');
        });

        e.target.classList.add('active');

        const key = e.target.getAttribute('data-key');
        currentTab = key;
        renderCurrentTabContent();

    });

    li.appendChild(a);
    tabList.appendChild(li);
});

async function loadContent(key) {
    generateContentButton.disabled = true;
    refreshContentButton.disabled = true;

    try {
        const variant = content[key].variant || '0';
        const seed = Math.round(1E6 * Math.random());
        // const response = await fetch(`http://127.0.0.1:8000/modify/${key}/${variant}/${seed}`);
        const response = await fetch(`https://intelligent-cass-llmad-dsp-19eea695.koyeb.app/modify/${key}/${variant}/${seed}`);
        if (response.ok) {
            firstTimeContentGenerated = true;
            const result = await response.json();
            // console.log(result);
            content[key].modifiedContent = result.content;
            content[key].variant = result.variant;

            const currentHeight = modifiedContentDiv.offsetHeight;

            renderCurrentTabContent();

            const newHeight = modifiedContentDiv.scrollHeight;
            if (
                newHeight > 0
                && Math.abs(newHeight - currentHeight) > 20
                && currentTab === key
            ) {
                const speed = 1000; // px/s
                const time = Math.max(0.2, Math.abs(newHeight - currentHeight) / speed);

                modifiedContentDiv.style.transition = `height ${time}s ease-in-out`;

                modifiedContentDiv.style.height = currentHeight + 'px';
                requestAnimationFrame(() => {
                    modifiedContentDiv.style.height = newHeight + "px";
                });

                modifiedContentDiv.addEventListener('transitionend', function onEnd(ev) {
                    if (ev.propertyName === 'height') {
                        modifiedContentDiv.style.height = null; // Reset to auto
                        modifiedContentDiv.removeEventListener('transitionend', onEnd);
                    }
                });
            }
        }
    } catch (error) {
        console.error("Error fetching content: ", error);
    }

    // hold buttons disabled a bit more
    setTimeout(() => {
        generateContentButton.removeAttribute('disabled');
        refreshContentButton.removeAttribute('disabled');
    }, 1000);

}

function onElementBecomeVisible(element, callback, options = { threshold: 0.5 }) {
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                callback();
                // Stop observing once triggered
                observer.disconnect();
            }
        });
    }, options);
    observer.observe(element);
}

onElementBecomeVisible(modifiedContentCardDiv, () => {
    setTimeout(() => {
        if (!firstTimeContentGenerated) {
            const key = generateContentButton.getAttribute('data-key');
            loadContent(key);
            window.gtag('event', 'generate_content_automatically', { source: 'script' });
        }
    }, 1500);
});

generateContentButton.addEventListener('click', (e) => {
    e.preventDefault();
    const key = generateContentButton.getAttribute('data-key');
    loadContent(key);
    window.gtag('event', 'generate_button_clicked', { source: 'generate_button' });
});
refreshContentButton.addEventListener('click', (e) => {
    e.preventDefault();
    const key = generateContentButton.getAttribute('data-key');
    loadContent(key);
    window.gtag('event', 'generate_button_clicked', { source: 'refresh_button' });
});


renderCurrentTabContent();